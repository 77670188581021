var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.showContent)?_c('div',{class:_setup.componentClass},[_c(_setup.Modal),_c(_setup.Notification),_c(_setup.ConfirmModal),_c('div',{class:`${_setup.componentClass}__content`},[(_setup.currentRoute.path !== '/login')?_c(_setup.HeaderV2):_vm._e(),(_setup.isAuthentificated)?_c('div',{class:`${_setup.componentClass}__column ${_setup.componentClass}__column_left ${
        _setup.currentRoute.path === '/ewa' ? 'wide' : ''
      }`},[(_setup.currentRoute.path !== '/ewa')?_c(_setup.SidebarNav):_c(_setup.EwaSideBar)],1):_vm._e(),_c('div',{class:`${_setup.componentClass}__column ${_setup.componentClass}__column_right ${
        _setup.isAuthentificated
          ? _setup.currentRoute.path !== '/ewa'
            ? 'has-left-col'
            : 'has-wide-left-col'
          : ''
      }`},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('router-view',{key:_vm.$route.fullPath,scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('transition',{attrs:{"name":"route","mode":"out-in"}},[_c(slotProps.Component,{tag:"component"})],1)]}}],null,false,1112286501)})],1)])])]),_c(_setup.UserTutorialComponent,{attrs:{"show":_setup.tutorialModalShow,"closeListener":_setup.closeTutorialModal}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }